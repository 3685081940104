import React, { useState, useEffect } from 'react';
import OrganizationChart from '@dabeng/react-orgchart';
import './App.css';

function App() {
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    fetch('https://takehome.abubakar-abdi.workers.dev/chart-data')
      .then(response => response.json())
      .then(data => {
        console.log("API Response Data:", data); // Logging the response data
        setDataSource(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="App">
      <div className="instructions">
        <h2>Organization Chart</h2>
        <p>Welcome to our interactive organizational chart. Here's how you can navigate:</p>
        <ul>
          <li><strong>Expand and Collapse a node:</strong> Click the down and up arrow to expand and collapse nodes.</li>
          <li><strong>Sibling nodes:</strong> Use right and left arrows to expand and collapse sibling nodes.</li>
          <li><strong>NOTE:</strong> Drag to view the entire org Chart.</li>
        </ul>
      </div>
      {dataSource ? (
        <OrganizationChart datasource={dataSource} />
      ) : (
        <p>Loading organization chart...</p>
      )}
    </div>
  );
}

export default App;
